export const receiptIntegers = [];
export const receiptFloats = [];
export const receiptIsoStrings = [];
export const receiptWhiteList = [
  'id',
  'consignmentItemId',
  'scannedData',
  'enteredData',
  'deliveryState',
];

export const receiptOptimisticList = ['consignmentItem', ...receiptWhiteList];
