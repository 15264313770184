import { useMemo } from 'react';
import {
  Button,
  Col,
  Row,
  Card,
  ButtonToolbar,
  ButtonGroup,
  Form,
} from 'react-bootstrap';
import { useQuery, useMutation, NetworkStatus } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { LinkContainer } from 'react-router-bootstrap';
import { Form as FinalForm } from 'react-final-form';
import setFieldTouched from 'final-form-set-field-touched';
import { v4 as uuidv4 } from 'uuid';

import get from 'lodash.get';
import cloneDeep from 'lodash.clonedeep';

import { toastSuccess, toastWarning, toastError } from '../lib/toast_helpers';
import { settingsSet } from '../store/settings_slice';

import { renderOverlay, renderError, renderOffline } from '../components/render_helpers';
import SubmitButtonSpinner from '../components/submit_button_spinner';
import Field from '../components/form/rff_field';
import InputField from '../components/form/input_field';
import ReactDateTimeField from '../components/form/react_date_time_field';
import { coerceInput, pickValues, handleSubmitError } from '../lib/utils';

import {
  consignmentItemFormPageQuery,
  consignmentItemCreate as consignmentItemCreateMutation,
  consignmentItemUpdate as consignmentItemUpdateMutation,
} from '../graphql/consignment_item_queries';
import * as updateFunctions from '../update_functions';
import { getConsignmentItemDefaultValues } from '../defaults';
import { consignmentItemFormValidator } from '../validators';
import { consignmentItemWhiteList } from '../white_lists';

const ConsignmentItemForm = () => {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const settingsTenant = useSelector((state) => state.settings.tenant);
  const settingsMutating = useSelector((state) => state.settings.mutating);
  const settingsOnline = useSelector((state) => state.settings.online);
  const [consignmentItemCreate] = useMutation(consignmentItemCreateMutation);
  const [consignmentItemUpdate] = useMutation(consignmentItemUpdateMutation);
  const {
    data: pageData,
    loading: pageLoading,
    error: pageError,
    networkStatus: pageNetworkStatus,
  } = useQuery(consignmentItemFormPageQuery, {
    variables: {
      hasConsignmentItemId: !!params.id,
      consignmentItemId: params.id || 0,
    },
    notifyOnNetworkStatusChange: true,
  });

  const pageLoadedOrRefetching = useMemo(
    () => !pageLoading || (pageLoading && pageNetworkStatus === NetworkStatus.refetch),
    [pageLoading, pageNetworkStatus]
  );

  const initialValues = useMemo(() => {
    if (pageData?.consignmentItem) {
      const values = pickValues(pageData.consignmentItem, consignmentItemWhiteList);
      return values;
    }
    return getConsignmentItemDefaultValues();
  }, [pageData]);

  const onCancel = () => {
    navigate('/consignment_items');
  };

  const onFormSubmit = async (data) => {
    const uuid = uuidv4();
    const submitData = cloneDeep(data);
    let mutation;
    let mutationMessageAction;
    const input = coerceInput(submitData);
    const mutationData = {
      variables: { input },
      context: {
        serializationKey: settingsTenant,
        tracked: true,
        recordType: 'ConsignmentItemType',
        recordId: params.id || uuid,
      },
    };
    if (params.id) {
      mutation = consignmentItemUpdate;
      mutationMessageAction = 'update';
      mutationData.variables.id = params.id;
      mutationData.context.mutationType = 'UPDATE';
      mutationData.update = updateFunctions.consignmentItemUpdate;
      mutationData.optimisticResponse = updateFunctions.optimistic(
        'consignmentItemUpdate',
        mutationData
      );
    } else {
      mutation = consignmentItemCreate;
      mutationData.context.mutationType = 'CREATE';
      mutationMessageAction = 'create';
      mutationData.update = updateFunctions.consignmentItemCreate;
      mutationData.optimisticResponse = updateFunctions.optimistic(
        'consignmentItemCreate',
        mutationData
      );
    }
    dispatch(
      settingsSet({
        mutating: true,
      })
    );
    if (settingsOnline) {
      try {
        await mutation(mutationData);
        toastSuccess(`Consignment Item ${mutationMessageAction} succeeded`);
        dispatch(
          settingsSet({
            mutating: false,
          })
        );
        navigate('/consignment_items');
      } catch (err) {
        const { errorMessage, submitErrors } = handleSubmitError(err);
        dispatch(
          settingsSet({
            mutating: false,
          })
        );
        toastError(errorMessage);
        return submitErrors;
      }
    } else {
      mutation(mutationData);
      toastWarning(
        `Consignment Item ${mutationMessageAction} ok locally. Go online to make permanent`
      );
      dispatch(
        settingsSet({
          mutating: false,
        })
      );
      navigate('/consignment_items');
    }
    return undefined;
  };

  // consignmentId
  // description
  // consignmentItemReference
  // consignmentItemType
  // quantity
  // weightKg
  // deliveryState
  // deliveryStatus
  // deliveryNotes
  // deliveredAt

  const renderContent = () => (
    <>
      <Row className="mt-4 mb-3">
        <Col sm={12}>
          <div className="float-none">
            <div className="float-start">
              <h1 className="h3 mb-3">
                {params.id ? 'Edit Consignment Item' : 'New Consignment Item'}
              </h1>
            </div>
            <div className="float-end">
              <LinkContainer to="/consignment_items">
                <Button className="me-2" variant="primary">
                  All Consignment Items
                </Button>
              </LinkContainer>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <FinalForm
            initialValues={initialValues}
            onSubmit={(data) => onFormSubmit(data)}
            validate={consignmentItemFormValidator}
            mutators={{ setFieldTouched }}
          >
            {({ handleSubmit, pristine, submitting }) => (
              <form noValidate>
                <Card>
                  <Card.Body>
                    <Field
                      name="consignmentId"
                      component={InputField}
                      asElement="select"
                      selectOptions={get(pageData, 'consignmentList', []).map(
                        ({ id, supplier: { name: supplierName }, name }) => ({
                          id,
                          name: [supplierName, name].join('-'),
                        })
                      )}
                      defaultSelectOptionText="select consignment..."
                      labelWidth={4}
                      inputWidth={4}
                      size="lg"
                    >
                      Consignment
                    </Field>
                    <Field
                      name="siteLocationId"
                      component={InputField}
                      asElement="select"
                      selectOptions={get(pageData, 'siteLocationList', [])}
                      defaultSelectOptionText="select site location..."
                      labelWidth={4}
                      inputWidth={4}
                      size="lg"
                    >
                      Site Location
                    </Field>
                    <Field
                      name="description"
                      component={InputField}
                      labelWidth={4}
                      inputWidth={8}
                      size="lg"
                    >
                      Description
                    </Field>
                    <Field
                      name="quantity"
                      component={InputField}
                      labelWidth={4}
                      inputWidth={2}
                      size="lg"
                    >
                      Quantity
                    </Field>
                    <Field
                      name="weightKg"
                      component={InputField}
                      labelWidth={4}
                      inputWidth={2}
                      size="lg"
                    >
                      Weight (kg)
                    </Field>
                    <Field
                      name="consignmentItemReference"
                      component={InputField}
                      labelWidth={4}
                      inputWidth={8}
                      size="lg"
                    >
                      Item Reference
                    </Field>
                    <Field
                      name="consignmentItemNumber"
                      component={InputField}
                      labelWidth={4}
                      inputWidth={8}
                      size="lg"
                    >
                      Item Number
                    </Field>
                    <Field
                      name="consignmentItemType"
                      component={InputField}
                      asElement="select"
                      selectOptions={Object.values(
                        get(pageData, 'enums.enums.ConsignmentItemTypes', [])
                      ).map((type) => ({
                        id: type,
                        name: type,
                      }))}
                      defaultSelectOptionText="select item type..."
                      labelWidth={4}
                      inputWidth={4}
                      size="lg"
                    >
                      Item Type
                    </Field>
                    <Field
                      name="deliveryStatus"
                      component={InputField}
                      asElement="select"
                      selectOptions={Object.values(
                        get(pageData, 'enums.enums.DeliveryStatuses', [])
                      ).map((type) => ({
                        id: type,
                        name: type,
                      }))}
                      defaultSelectOptionText="select delivery status..."
                      labelWidth={4}
                      inputWidth={4}
                      size="lg"
                    >
                      Delivery Status
                    </Field>
                    <Field
                      name="deliveryState"
                      component={InputField}
                      asElement="select"
                      selectOptions={Object.values(
                        get(pageData, 'enums.enums.DeliveryStates', [])
                      ).map((type) => ({
                        id: type,
                        name: type,
                      }))}
                      defaultSelectOptionText="select delivery state..."
                      labelWidth={4}
                      inputWidth={4}
                      size="lg"
                    >
                      Delivery State
                    </Field>
                    <Field
                      size="lg"
                      name="deliveredAt"
                      labelWidth={4}
                      inputWidth={4}
                      component={ReactDateTimeField}
                      helpText="ddd Do MMMM YYYY HH:mm"
                      dateFormat="ddd Do MMMM YYYY"
                      timeFormat="HH:mm"
                      closeOnSelect
                      // initialViewMode="time"
                    >
                      Delivered At
                    </Field>
                    <Field
                      name="deliveryNotes"
                      component={InputField}
                      labelWidth={4}
                      inputWidth={8}
                      size="lg"
                      asElement="textarea"
                      rows={4}
                    >
                      Delivery Notes
                    </Field>
                    <Form.Group as={Row}>
                      <Col sm={12}>
                        <ButtonToolbar style={{ justifyContent: 'flex-end' }}>
                          <ButtonGroup className="me-2">
                            <Button
                              variant="danger"
                              onClick={onCancel}
                              disabled={submitting}
                            >
                              Cancel
                            </Button>
                            <Button
                              type="button"
                              variant="primary"
                              disabled={pristine || submitting}
                              onClick={handleSubmit}
                            >
                              {submitting && <SubmitButtonSpinner />}
                              {params.id ? 'Update' : 'Create'}
                            </Button>
                          </ButtonGroup>
                        </ButtonToolbar>
                      </Col>
                    </Form.Group>
                  </Card.Body>
                </Card>
              </form>
            )}
          </FinalForm>
        </Col>
      </Row>
    </>
  );

  return (
    <div>
      {renderOverlay(pageLoading, settingsMutating, settingsOnline)}
      {renderOffline(settingsOnline)}
      {renderError(pageError)}
      {!pageError && pageLoadedOrRefetching && renderContent()}
    </div>
  );
};

export default ConsignmentItemForm;
