import { useMemo } from 'react';
import { Col, Row, Card, Button } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { useQuery, useMutation, NetworkStatus } from '@apollo/client';
import { useSelector, useDispatch } from 'react-redux';
import { validate as uuidValidate } from 'uuid';

import { renderOverlay, renderOffline, renderError } from '../components/render_helpers';
import ReactTable from '../components/react_table/react_table';
import { toastSuccess, toastWarning, toastError } from '../lib/toast_helpers';
import { settingsSet } from '../store/settings_slice';
import {
  siteLocationListPageQuery,
  siteLocationDelete as siteLocationDeleteMutation,
} from '../graphql/site_location_queries';
import * as updateFunctions from '../update_functions';

const SiteLocationList = () => {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.auth.user);
  const settingsMutating = useSelector((state) => state.settings.mutating);
  const settingsOnline = useSelector((state) => state.settings.online);
  const settingsTenant = useSelector((state) => state.settings.tenant);
  const [siteLocationDelete] = useMutation(siteLocationDeleteMutation);
  const {
    data: pageData,
    loading: pageLoading,
    error: pageError,
    refetch: pageRefetch,
    networkStatus: pageNetworkStatus,
    client: pageClient,
  } = useQuery(siteLocationListPageQuery, {
    notifyOnNetworkStatusChange: true,
    onCompleted: ({ siteLocationList }) => {
      // write an individual query for every siteLocation in the list
      updateFunctions.siteLocationList(pageClient, siteLocationList);
    },
  });
  const pageLoadedOrRefetching = useMemo(
    () => !pageLoading || (pageLoading && pageNetworkStatus === NetworkStatus.refetch),
    [pageLoading, pageNetworkStatus]
  );

  const siteLocationDeleteClicked = async (e) => {
    const dataId = e.currentTarget.getAttribute('data-id');
    const siteLocationId = uuidValidate(dataId)
      ? dataId
      : parseInt(e.currentTarget.getAttribute('data-id'), 10);
    const mutationData = {
      variables: { id: siteLocationId },
      context: {
        serializationKey: settingsTenant,
        tracked: true,
        recordType: 'SiteLocationType',
        recordId: siteLocationId,
        mutationType: 'DELETE',
      },
      update: updateFunctions.siteLocationDelete,
    };
    mutationData.optimisticResponse = updateFunctions.optimistic(
      'siteLocationDelete',
      mutationData
    );
    dispatch(
      settingsSet({
        mutating: true,
      })
    );
    if (settingsOnline) {
      try {
        await siteLocationDelete(mutationData);
        toastSuccess('SiteLocation delete ok');
      } catch (err) {
        console.log(err.toString());
        toastError('SiteLocation delete failed');
      } finally {
        dispatch(
          settingsSet({
            mutating: false,
          })
        );
      }
    } else {
      siteLocationDelete(mutationData);
      toastWarning(`SiteLocation delete ok locally. Go online to make permanent`);
      dispatch(
        settingsSet({
          mutating: false,
        })
      );
    }
  };

  const getSiteName = (row) => {
    const {
      site: { name: siteName },
    } = row;
    return siteName;
  };

  const parentColumns = [
    {
      header: 'Id',
      accessorKey: 'id',
    },
    {
      header: 'Site',
      id: 'site',
      accessorFn: (row) => getSiteName(row),
      filterType: 'dropdown',
    },
    {
      header: 'Name',
      accessorKey: 'name',
      enableColumnFilter: true,
      filterFn: 'includesString',
    },
    {
      header: 'Type',
      accessorKey: 'siteLocationType',
      filterType: 'dropdown',
    },
  ];

  const renderContent = () => (
    <>
      <Row className="mt-4 mb-3">
        <Col sm={12}>
          <div className="float-none">
            <div className="float-start">
              <h1 className="h3 mb-3">Site Locations</h1>
            </div>
            <div className="float-end">
              <Button
                className="me-2"
                variant="primary"
                onClick={() => pageRefetch()}
                disabled={!settingsOnline}
              >
                Refresh
              </Button>
              <LinkContainer to="/site_locations/new">
                <Button variant="primary">New Site Location</Button>
              </LinkContainer>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <Card.Body>
              <ReactTable
                rootName="siteLocation"
                parentColumns={parentColumns}
                data={pageData.siteLocationList}
                doShow={false}
                handleDelete={siteLocationDeleteClicked}
                currentUser={currentUser}
                hiddenColumns={['id']}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );

  return (
    <div>
      {renderOverlay(pageLoading, settingsMutating, settingsOnline)}
      {renderOffline(settingsOnline)}
      {renderError(pageError)}
      {!pageError && pageLoadedOrRefetching && renderContent()}
    </div>
  );
};

export default SiteLocationList;
