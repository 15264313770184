import { ApolloClient, ApolloLink, HttpLink, Observable } from '@apollo/client';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import QueueLink from 'apollo-link-queue';
import { RetryLink } from '@apollo/client/link/retry';
import SerializingLink from 'apollo-link-serialize';
import { onError } from '@apollo/client/link/error';
import { InMemoryCache } from '@apollo/client/cache';
import { CachePersistor, LocalForageWrapper } from 'apollo3-cache-persist';
import localForage from 'localforage';
import { validate as uuidValidate } from 'uuid';

import get from 'lodash.get';
import keys from 'lodash.keys';
import omit from 'lodash.omit';
import pick from 'lodash.pick';

import subscriptionClient from './subscription_client';
import {
  getIdToken,
  apolloSchemaVersionKey,
  apolloTrackedMutationsKey,
  apolloTrackedUuidsKey,
  apolloCachePersistKey,
} from './local_storage';

import * as updateFunctions from '../update_functions';
import hydrateQuery from '../graphql/hydrate_query';

const expectedSchemaVersion = '1';
const currentSchemaVersion = localStorage.getItem(apolloSchemaVersionKey);

const httpLinkOptions = {
  uri: `${process.env.EXPRESS_API_URL}/graphql`,
  headers: { 'Apollo-Require-Preflight': 'true' },
};

const noBatchLinkSplit = ApolloLink.split(
  (operation) => operation.getContext().noBatch,
  new HttpLink(httpLinkOptions),
  new BatchHttpLink(httpLinkOptions)
);

const hasUploadLinkSplit = ApolloLink.split(
  (operation) => operation.getContext().hasUpload,
  createUploadLink(httpLinkOptions),
  noBatchLinkSplit
);

const operationDefinitionLinkSplit = ApolloLink.split(
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query);
    return kind === 'OperationDefinition' && operation === 'subscription';
  },
  new GraphQLWsLink(subscriptionClient),
  hasUploadLinkSplit
);

const authLink = new ApolloLink((operation, forward) => {
  operation.setContext({
    headers: {
      authorization: `Bearer ${getIdToken()}`,
    },
  });
  return forward(operation);
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  const reportError = window.$NODE_ENV !== 'development';
  let isUnauthorized = false;
  let isForbidden = false;

  if (networkError) {
    isUnauthorized = networkError.statusCode === 401;
    if (isUnauthorized && reportError) {
      window.Rollbar.info(networkError, networkError.result);
    }
  }

  if (graphQLErrors && Array.isArray(graphQLErrors)) {
    isUnauthorized = graphQLErrors.some(
      (graphQLError) => get(graphQLError, 'extensions.code') === 'UNAUTHENTICATED'
    );
    if (isUnauthorized && reportError) {
      const graphQLError = graphQLErrors.find(
        (err) => get(err, 'extensions.code') === 'UNAUTHENTICATED'
      );
      window.Rollbar.info('UNAUTHENTICATED', graphQLError);
    }

    isForbidden = graphQLErrors.some(
      (graphQLError) => get(graphQLError, 'extensions.code') === 'FORBIDDEN'
    );
    if (isForbidden && reportError) {
      const graphQLError = graphQLErrors.find(
        (err) => get(err, 'extensions.code') === 'FORBIDDEN'
      );
      window.Rollbar.info('FORBIDDEN', graphQLError);
    }
  }

  if (window.$NODE_ENV === 'development') {
    console.log('NETWORK: ', JSON.stringify(networkError, undefined, 2));
    console.log('GRAPHQL: ', JSON.stringify(graphQLErrors, undefined, 2));
    console.log({ reportError, isUnauthorized, isForbidden });
  }

  if (isUnauthorized || isForbidden) {
    // TODO brittle
    // console.log(window.location.pathname);
    if (!window.location.pathname.includes('login')) {
      window.location = '/auth/logout';
    }
  } else if (networkError && reportError) {
    window.Rollbar.error(networkError, networkError.result);
  } else if (graphQLErrors && Array.isArray(graphQLErrors) && reportError) {
    graphQLErrors.forEach((graphQLError) => {
      const code = get(graphQLError, 'extensions.code', '[GraphQL error]');
      if (code === 'BAD_USER_INPUT') {
        window.Rollbar.info(
          code,
          pick(get(graphQLError, ['extensions']), ['code', 'data', 'config'])
        );
      } else {
        window.Rollbar.error(code, graphQLError);
      }
    });
  }
});

const retryLink = new RetryLink({ attempts: { max: Infinity } });
const queueLink = new QueueLink();
queueLink.close();

const serializingLink = new SerializingLink();

const getTrackedUuids = () =>
  JSON.parse(localStorage.getItem(apolloTrackedUuidsKey) || null) || {};

const setTrackedUuids = (trackedUuids) =>
  localStorage.setItem(apolloTrackedUuidsKey, JSON.stringify(trackedUuids));

const storeTrackedUuid = (trackedKey, trackedUuid) => {
  const trackedUuids = getTrackedUuids();
  setTrackedUuids({ ...trackedUuids, [trackedKey]: trackedUuid });
};

const getTrackedMutations = () =>
  JSON.parse(localStorage.getItem(apolloTrackedMutationsKey) || null) || {};

const setTrackedMutations = (trackedMutations) =>
  localStorage.setItem(apolloTrackedMutationsKey, JSON.stringify(trackedMutations));

const storeTrackedMutation = (trackedKey, trackedMutation) => {
  const trackedMutations = getTrackedMutations();
  setTrackedMutations({ ...trackedMutations, [trackedKey]: trackedMutation });
};

const deleteTrackedMutation = (trackedKey) => {
  const trackedMutations = getTrackedMutations();
  setTrackedMutations(omit(trackedMutations, [trackedKey]));
};

const trackerLink = new ApolloLink((operation, forward) => {
  if (forward === undefined) return null;
  const trackedKey = Date.now();
  const context = operation.getContext();
  if (context.tracked) {
    const { operationName, query, variables } = operation;
    storeTrackedMutation(trackedKey, {
      query,
      context: omit(context, ['cache', 'getCacheKey']),
      variables,
      operationName,
    });
  }

  return forward(operation).map((data) => {
    if (context.tracked) {
      deleteTrackedMutation(trackedKey);
    }
    return data;
  });
});

const idLink = new ApolloLink((operation, forward) => {
  if (forward === undefined) return null;
  const { operationName } = operation;
  const context = operation.getContext();
  const { tracked, recordId, mutationType } = context;

  if (
    tracked &&
    mutationType &&
    mutationType !== 'CREATE' &&
    uuidValidate(operation.variables.id)
  ) {
    const reqTrackedUuids = getTrackedUuids();
    const reqTrackedMutations = getTrackedMutations();
    const serverId = reqTrackedUuids[operation.variables.id];
    if (serverId) {
      // eslint-disable-next-line no-param-reassign
      operation.variables.id = serverId;
      if (operation.variables.input) {
        // eslint-disable-next-line no-param-reassign
        operation.variables.input.id = serverId;
      }
    } else {
      if (window.$NODE_ENV !== 'development') {
        window.Rollbar.info('Dropped sync mutation', context);
      }
      // Terminate mutation and head back up the chain
      return new Observable((observer) => {
        Promise.resolve({ data: { [operationName]: null } }).then((result) => {
          observer.next(result);
          observer.complete();
          return result;
        });
      });
    }
    if (keys(reqTrackedMutations).length === 1) {
      setTrackedUuids({});
    }
  }

  return forward(operation).map((data) => {
    if (tracked && mutationType === 'CREATE' && uuidValidate(recordId)) {
      storeTrackedUuid(recordId, data.data[operationName].id);
    }
    return data;
  });
});

const link = ApolloLink.from([
  trackerLink,
  queueLink,
  serializingLink,
  idLink,
  retryLink,
  errorLink,
  authLink,
  operationDefinitionLinkSplit,
]);

const cache = new InMemoryCache({
  addTypename: true,
  typePolicies: {
    Query: {
      fields: {
        consignmentList: {
          merge: false,
        },
        consignmentItemList: {
          merge: false,
        },
        consignmentImageList: {
          merge: false,
        },
        productList: {
          merge: false,
        },
        productProductList: {
          merge: false,
        },
        productSupplierCatalogItemList: {
          merge: false,
        },
        siteList: {
          merge: false,
        },
        siteLocationList: {
          merge: false,
        },
        userList: {
          merge: false,
        },
        receiptList: {
          merge: false,
        },
        manufacturerList: {
          merge: false,
        },
        supplierList: {
          merge: false,
        },
        supplierCatalogItemList: {
          merge: false,
        },
        supplierCatalogList: {
          merge: false,
        },
        purchaserList: {
          merge: false,
        },
        productCategoryList: {
          merge: false,
        },
      },
    },
  },
});

const apolloPersistor = new CachePersistor({
  cache,
  // storage: localStorage,
  // 5 MB
  // maxSize: 5242880,
  storage: new LocalForageWrapper(localForage),
  key: apolloCachePersistKey,
  // 10 MB
  maxSize: 10485760,
  // debug: true,
});

const apolloClient = new ApolloClient({
  link,
  cache,
  connectToDevTools: process.env.NODE_ENV !== 'production',
  // // eslint-disable-next-line no-underscore-dangle
  // cache: cache.restore(window.__APOLLO_STATE__ || {}),
  // shouldBatch: true,
  // local state
  // https://blog.apollographql.com/announcing-apollo-client-2-5-c12230cabbb7
  // resolvers: { ... },
  // typeDefs: { ... },
});

const restoreApolloCache = async () => {
  if (currentSchemaVersion === expectedSchemaVersion) {
    await apolloPersistor.restore();
  } else {
    await apolloPersistor.purge();
    localStorage.setItem(apolloSchemaVersionKey, expectedSchemaVersion);
  }
};

const restoreTrackedMutations = async () => {
  const trackedMutations = getTrackedMutations();
  setTrackedMutations({});
  const trackedMutationsKeys = keys(trackedMutations).sort();
  trackedMutationsKeys.map((trackedKey) => {
    const { variables, query, context, operationName } = trackedMutations[trackedKey];
    if (operationName !== 'imageCreate') {
      return apolloClient.mutate({
        context,
        variables,
        mutation: query,
        update: updateFunctions[operationName],
        optimisticResponse: context.optimisticResponse,
      });
    }
    return undefined;
  });
};

const hydrateCache = async () => {
  apolloClient.query({
    query: hydrateQuery,
    fetchPolicy: 'network-only',
  });
};

export {
  restoreApolloCache,
  restoreTrackedMutations,
  hydrateCache,
  apolloClient,
  queueLink,
  subscriptionClient,
};
