import { useMemo, useCallback, useRef, useState } from 'react';
import { Modal, Button, ButtonToolbar, ButtonGroup } from 'react-bootstrap';
import { useMutation } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { Form as FinalForm } from 'react-final-form';
import setFieldTouched from 'final-form-set-field-touched';
import { v4 as uuidv4 } from 'uuid';
import Webcam from 'react-webcam';
import { DateTime } from 'luxon';

import cloneDeep from 'lodash.clonedeep';

import SubmitButtonSpinner from '../../components/submit_button_spinner';
import { toastSuccess, toastWarning, toastError } from '../../lib/toast_helpers';
import { settingsSet } from '../../store/settings_slice';
import {
  consignmentImageCreate as consignmentImageCreateMutation,
  consignmentImageUpdate as consignmentImageUpdateMutation,
} from '../../graphql/consignment_image_queries';
import * as updateFunctions from '../../update_functions';
import { coerceInput, pickValues, handleSubmitError } from '../../lib/utils';
import { consignmentImageDefaultValues } from '../../defaults';
import { consignmentImageWhiteList } from '../../white_lists';

function ConsignmentImageCaptureModal(props) {
  let closeOnSubmit = true;
  const dispatch = useDispatch();
  const divRef = useRef(null);
  const webcamRef = useRef(null);
  const [imageBase64, setImageBase64] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [consignmentImageCreate] = useMutation(consignmentImageCreateMutation);
  const [consignmentImageUpdate] = useMutation(consignmentImageUpdateMutation);
  const {
    settingsTenant,
    settingsOnline,
    show,
    consignmentId,
    consignmentImage,
    onCancel,
    onComplete,
  } = props;

  const initialValues = useMemo(() => {
    if (consignmentImage) {
      const values = pickValues(consignmentImage, consignmentImageWhiteList);
      return { ...values, consignmentId };
    }
    return { ...consignmentImageDefaultValues, consignmentId };
  }, [consignmentImage, consignmentId]);

  const dataURLtoFile = (dataurl, filename) => {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[arr.length - 1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n) {
      u8arr[n] = bstr.charCodeAt(n);
      n -= 1;
    }
    return new File([u8arr], filename, { type: mime });
  };

  const capture = useCallback(() => {
    const newImageBase64 = webcamRef.current.getScreenshot({
      // width: divRef.current.clientWidth,
      height: divRef.current.clientHeight,
    });
    setImageBase64(newImageBase64);
    const fileName = `consignment-image-${DateTime.now().toISO()}.jpeg`;
    const newImageFile = dataURLtoFile(newImageBase64, fileName);
    setImageFile(newImageFile);
  }, [divRef, webcamRef]);

  const retake = () => {
    setImageBase64(null);
    setImageFile(null);
  };

  const onFormSubmit = async (data) => {
    const uuid = uuidv4();
    const submitData = cloneDeep(data);
    let mutation;
    let mutationMessageAction;
    const input = coerceInput(submitData);
    input.file = imageFile;
    // console.log(input);
    const mutationData = {
      variables: { input },
      context: {
        hasUpload: true,
        serializationKey: settingsTenant,
        tracked: true,
        recordType: 'ConsignmentImageType',
        recordId: consignmentImage ? consignmentImage.id : uuid,
      },
    };
    if (consignmentImage?.id) {
      mutation = consignmentImageUpdate;
      mutationMessageAction = 'update';
      mutationData.variables.id = consignmentImage.id;
      mutationData.context.mutationType = 'UPDATE';
      mutationData.update = updateFunctions.consignmentImageUpdate;
      mutationData.optimisticResponse = updateFunctions.optimisticNew({
        mutationName: 'consignmentImageUpdate',
        mutationData,
      });
    } else {
      mutation = consignmentImageCreate;
      mutationData.context.mutationType = 'CREATE';
      mutationMessageAction = 'create';
      mutationData.update = updateFunctions.consignmentImageCreate;
      mutationData.optimisticResponse = updateFunctions.optimisticNew({
        mutationName: 'consignmentImageCreate',
        mutationData,
      });
    }
    dispatch(
      settingsSet({
        mutating: true,
      })
    );
    if (settingsOnline) {
      try {
        await mutation(mutationData);
        toastSuccess(`Consignment Image ${mutationMessageAction} succeeded`);
        dispatch(
          settingsSet({
            mutating: false,
          })
        );
        retake();
        if (closeOnSubmit) {
          onComplete();
        }
      } catch (err) {
        const { errorMessage, submitErrors } = handleSubmitError(err);
        dispatch(
          settingsSet({
            mutating: false,
          })
        );
        toastError(errorMessage);
        return submitErrors;
      }
    } else {
      mutation(mutationData);
      toastWarning(
        `Consignment Image ${mutationMessageAction} ok locally. Go online to make permanent`
      );
      dispatch(
        settingsSet({
          mutating: false,
        })
      );
      retake();
      if (closeOnSubmit) {
        onComplete();
      }
    }
    return undefined;
  };

  return (
    <Modal
      id={`consignment-${consignmentId}-consignment-image-form`}
      show={show}
      onHide={() => {
        retake();
        onCancel();
      }}
      fullscreen
    >
      <FinalForm
        onSubmit={onFormSubmit}
        initialValues={initialValues}
        mutators={{ setFieldTouched }}
      >
        {({ handleSubmit, submitting }) => (
          <form noValidate onSubmit={handleSubmit}>
            <Modal.Body
              style={{
                height: 'calc(100vh - 68px)', // footer
              }}
            >
              {imageBase64 && <img src={imageBase64} alt="webcam" />}
              <div
                ref={divRef}
                style={{ height: '100%', display: imageBase64 ? 'none' : 'inherit' }}
              >
                <Webcam
                  style={{
                    height: 'calc(100vh - 96px)',
                    width: 'auto',
                    // objectFit: 'fill',
                    objectFit: 'cover', // use "cover" to avoid distortion
                    position: 'absolute',
                  }}
                  ref={webcamRef}
                  screenshotFormat="image/jpeg"
                  screenshotQuality={0.92}
                />
              </div>
            </Modal.Body>
            <Modal.Footer>
              <ButtonToolbar className="d-flex justify-content-end w-100">
                <ButtonGroup className="me-2">
                  <Button
                    variant="danger"
                    onClick={() => {
                      retake();
                      onCancel();
                    }}
                    disabled={submitting}
                  >
                    Cancel
                  </Button>
                </ButtonGroup>
                <ButtonGroup className="me-2">
                  {imageBase64 ? (
                    <Button variant="danger" onClick={retake}>
                      Retake
                    </Button>
                  ) : (
                    <Button variant="danger" onClick={capture}>
                      Capture
                    </Button>
                  )}
                  {/* <Button
                    type="button"
                    variant="primary"
                    disabled={submitting || !imageFile}
                    onClick={handleSubmit}
                  >
                    {submitting && <SubmitButtonSpinner />}
                    {consignmentImage ? 'Replace' : 'Save'}
                  </Button> */}
                </ButtonGroup>
                <ButtonGroup className="me-2">
                  <Button
                    type="button"
                    variant="primary"
                    disabled={submitting || !imageFile}
                    onClick={(e) => {
                      closeOnSubmit = false;
                      handleSubmit(e);
                    }}
                  >
                    {submitting && <SubmitButtonSpinner />}
                    {consignmentImage ? 'Update and New' : 'Create and New'}
                  </Button>
                  <Button
                    type="button"
                    variant="primary"
                    disabled={submitting || !imageFile}
                    onClick={(e) => {
                      closeOnSubmit = true;
                      handleSubmit(e);
                    }}
                  >
                    {submitting && <SubmitButtonSpinner />}
                    {consignmentImage ? 'Update and Close' : 'Create and Close'}
                  </Button>
                </ButtonGroup>
              </ButtonToolbar>
            </Modal.Footer>
          </form>
        )}
      </FinalForm>
    </Modal>
  );
}

export default ConsignmentImageCaptureModal;
