import { useMemo } from 'react';
import { Modal, Button, ButtonToolbar, ButtonGroup } from 'react-bootstrap';
import { useMutation } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { Form as FinalForm } from 'react-final-form';
import setFieldTouched from 'final-form-set-field-touched';
import { v4 as uuidv4 } from 'uuid';

import get from 'lodash.get';
import cloneDeep from 'lodash.clonedeep';

import Field from '../../components/form/rff_field';
import InputField from '../../components/form/input_field';
import SubmitButtonSpinner from '../../components/submit_button_spinner';
import { toastSuccess, toastWarning, toastError } from '../../lib/toast_helpers';
import { settingsSet } from '../../store/settings_slice';
import {
  consignmentItemCreate as consignmentItemCreateMutation,
  consignmentItemUpdate as consignmentItemUpdateMutation,
} from '../../graphql/consignment_item_queries';
import * as updateFunctions from '../../update_functions';
import { coerceInput, pickValues, handleSubmitError } from '../../lib/utils';
import { getConsignmentItemDefaultValues } from '../../defaults';
import { consignmentItemFormValidator } from '../../validators';
import { consignmentItemWhiteList } from '../../white_lists';

function ConsignmentItemFormModal(props) {
  const dispatch = useDispatch();
  const [consignmentItemCreate] = useMutation(consignmentItemCreateMutation);
  const [consignmentItemUpdate] = useMutation(consignmentItemUpdateMutation);
  const {
    settingsTenant,
    settingsOnline,
    show,
    consignmentId,
    consignmentName,
    consignmentItem,
    enums,
    onCancel,
    onComplete,
    siteLocations,
  } = props;

  const initialValues = useMemo(() => {
    if (consignmentItem) {
      const values = pickValues(consignmentItem, consignmentItemWhiteList);
      return { ...values, consignmentId };
    }
    return { ...getConsignmentItemDefaultValues(), consignmentId };
  }, [consignmentItem, consignmentId]);

  const onFormSubmit = async (data) => {
    const uuid = uuidv4();
    const submitData = cloneDeep(data);
    let mutation;
    let mutationMessageAction;
    const input = coerceInput(submitData);
    const mutationData = {
      variables: { input },
      context: {
        serializationKey: settingsTenant,
        tracked: true,
        recordType: 'ConsignmentItemType',
        recordId: consignmentItem ? consignmentItem.id : uuid,
      },
    };
    if (consignmentItem?.id) {
      mutation = consignmentItemUpdate;
      mutationMessageAction = 'update';
      mutationData.variables.id = consignmentItem.id;
      mutationData.context.mutationType = 'UPDATE';
      mutationData.update = updateFunctions.consignmentItemUpdate;
      mutationData.optimisticResponse = updateFunctions.optimisticNew({
        mutationName: 'consignmentItemUpdate',
        mutationData,
      });
    } else {
      mutation = consignmentItemCreate;
      mutationData.context.mutationType = 'CREATE';
      mutationMessageAction = 'create';
      mutationData.update = updateFunctions.consignmentItemCreate;
      mutationData.optimisticResponse = updateFunctions.optimisticNew({
        mutationName: 'consignmentItemCreate',
        mutationData,
      });
    }
    dispatch(
      settingsSet({
        mutating: true,
      })
    );
    if (settingsOnline) {
      try {
        await mutation(mutationData);
        toastSuccess(`Consignment Item ${mutationMessageAction} succeeded`);
        dispatch(
          settingsSet({
            mutating: false,
          })
        );
        onComplete();
      } catch (err) {
        const { errorMessage, submitErrors } = handleSubmitError(err);
        dispatch(
          settingsSet({
            mutating: false,
          })
        );
        toastError(errorMessage);
        return submitErrors;
      }
    } else {
      mutation(mutationData);
      toastWarning(
        `Consignment Item ${mutationMessageAction} ok locally. Go online to make permanent`
      );
      dispatch(
        settingsSet({
          mutating: false,
        })
      );
      onComplete();
    }
    return undefined;
  };

  return (
    <Modal
      id={`consignment-${consignmentId}-consignment-item-form`}
      show={show}
      onHide={onCancel}
      centered
      size="xl"
      animation={false}
    >
      <FinalForm
        onSubmit={onFormSubmit}
        initialValues={initialValues}
        validate={consignmentItemFormValidator}
        mutators={{ setFieldTouched }}
      >
        {({ handleSubmit, submitting, pristine }) => (
          <form noValidate onSubmit={handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title>{`Add Item for ${consignmentName}`}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <fieldset className="border rounded-3 p-3">
                <legend className="float-none w-auto px-3 fs-6">Item details</legend>
                <Field
                  name="siteLocationId"
                  component={InputField}
                  asElement="select"
                  selectOptions={siteLocations}
                  defaultSelectOptionText="select initial site location..."
                  labelWidth={4}
                  inputWidth={4}
                  size="lg"
                >
                  Site Location
                </Field>
                <Field
                  name="description"
                  component={InputField}
                  labelWidth={4}
                  inputWidth={8}
                  size="lg"
                >
                  Description
                </Field>
                <Field
                  name="quantity"
                  component={InputField}
                  labelWidth={4}
                  inputWidth={2}
                  size="lg"
                >
                  Quantity
                </Field>
                <Field
                  name="weightKg"
                  component={InputField}
                  labelWidth={4}
                  inputWidth={2}
                  size="lg"
                >
                  Weight (kg)
                </Field>
                <Field
                  name="consignmentItemReference"
                  component={InputField}
                  labelWidth={4}
                  inputWidth={8}
                  size="lg"
                >
                  Item Reference
                </Field>
                <Field
                  name="consignmentItemNumber"
                  component={InputField}
                  labelWidth={4}
                  inputWidth={8}
                  size="lg"
                >
                  Item Number
                </Field>
                <Field
                  name="consignmentItemType"
                  component={InputField}
                  asElement="select"
                  selectOptions={Object.values(
                    get(enums, 'enums.ConsignmentItemTypes', [])
                  ).map((type) => ({
                    id: type,
                    name: type,
                  }))}
                  defaultSelectOptionText="select item type..."
                  labelWidth={4}
                  inputWidth={4}
                  size="lg"
                >
                  Item Type
                </Field>
                <Field
                  name="deliveryStatus"
                  component={InputField}
                  asElement="select"
                  selectOptions={Object.values(
                    get(enums, 'enums.DeliveryStatuses', [])
                  ).map((type) => ({
                    id: type,
                    name: type,
                  }))}
                  defaultSelectOptionText="select delivery status..."
                  labelWidth={4}
                  inputWidth={4}
                  size="lg"
                >
                  Delivery Status
                </Field>
                <Field
                  name="deliveryState"
                  component={InputField}
                  asElement="select"
                  selectOptions={Object.values(
                    get(enums, 'enums.DeliveryStates', [])
                  ).map((type) => ({
                    id: type,
                    name: type,
                  }))}
                  defaultSelectOptionText="select delivery state..."
                  labelWidth={4}
                  inputWidth={4}
                  size="lg"
                >
                  Delivery State
                </Field>
                <Field
                  name="deliveryNotes"
                  component={InputField}
                  labelWidth={4}
                  inputWidth={8}
                  size="lg"
                  asElement="textarea"
                  rows={2}
                >
                  Delivery Notes
                </Field>
              </fieldset>
            </Modal.Body>
            <Modal.Footer>
              <ButtonToolbar className="d-flex justify-content-end w-100">
                <ButtonGroup className="me-2">
                  <Button variant="danger" onClick={onCancel} disabled={submitting}>
                    Cancel
                  </Button>
                  <Button
                    type="button"
                    variant="primary"
                    disabled={pristine || submitting}
                    onClick={handleSubmit}
                  >
                    {submitting && <SubmitButtonSpinner />}
                    {consignmentItem ? 'Update' : 'Create'}
                  </Button>
                </ButtonGroup>
              </ButtonToolbar>
            </Modal.Footer>
          </form>
        )}
      </FinalForm>
    </Modal>
  );
}

export default ConsignmentItemFormModal;
