import { useMutation } from '@apollo/client';
import { validate as uuidValidate } from 'uuid';
import { useDispatch } from 'react-redux';
import ModalImage from 'react-modal-image';

import get from 'lodash.get';

import ReactTable from '../../components/react_table/react_table';

import { consignmentImageDelete as consignmentImageDeleteMutation } from '../../graphql/consignment_image_queries';
import { toastSuccess, toastWarning, toastError } from '../../lib/toast_helpers';
import { settingsSet } from '../../store/settings_slice';
import * as updateFunctions from '../../update_functions';

const ConsignmentImageList = (props) => {
  const { currentUser, consignmentImages, settingsTenant, settingsOnline, onDelete } =
    props;

  const dispatch = useDispatch();
  const [consignmentImageDelete] = useMutation(consignmentImageDeleteMutation);

  const consignmentImageDeleteClicked = async (e) => {
    const dataId = e.currentTarget.getAttribute('data-id');
    const consignmentImageId = uuidValidate(dataId)
      ? dataId
      : parseInt(e.currentTarget.getAttribute('data-id'), 10);
    const mutationData = {
      variables: { id: consignmentImageId },
      context: {
        serializationKey: settingsTenant,
        tracked: true,
        recordType: 'ConsignmentImageType',
        recordId: consignmentImageId,
        mutationType: 'DELETE',
      },
      update: updateFunctions.consignmentImageDelete,
    };
    mutationData.optimisticResponse = updateFunctions.optimisticNew({
      mutationName: 'consignmentImageDelete',
      mutationData,
    });
    dispatch(
      settingsSet({
        mutating: true,
      })
    );
    if (settingsOnline) {
      try {
        await consignmentImageDelete(mutationData);
        toastSuccess('Consignment Image delete ok');
      } catch (err) {
        console.log(err.toString());
        toastError('Consignment Image delete failed');
      } finally {
        dispatch(
          settingsSet({
            mutating: false,
          })
        );
        onDelete();
      }
    } else {
      consignmentImageDelete(mutationData);
      toastWarning(`Consignment Image delete ok locally. Go online to make permanent`);
      dispatch(
        settingsSet({
          mutating: false,
        })
      );
      onDelete();
    }
  };

  const getConsignmentItemName = (row) => {
    const { consignmentItem } = row;
    const { consignmentItemReference } = consignmentItem || {};
    return consignmentItemReference || 'None';
  };

  const renderSignedUrl = ({ row }) => {
    const signedUrlOriginal = get(row, ['original', 'signedUrlOriginal']);
    const fileFilename = get(row, ['original', 'fileFilename'], 'Original');
    if (signedUrlOriginal) {
      return (
        <ModalImage
          small="https://upload.wikimedia.org/wikipedia/commons/thumb/f/f2/WPCA_-_Picture_icon_gray_infobox.svg/25px-WPCA_-_Picture_icon_gray_infobox.svg.png"
          large={signedUrlOriginal}
          alt={fileFilename}
        />
      );
    }
    return undefined;
  };

  const parentColumns = [
    {
      header: 'Id',
      accessorKey: 'id',
    },
    {
      header: 'Consignment Item',
      id: 'consignmentItem',
      accessorFn: (row) => getConsignmentItemName(row),
      filterType: 'dropdown',
    },
    {
      header: 'Filename',
      accessorKey: 'fileFilename',
      enableColumnFilter: false,
    },
    {
      header: 'Filetype',
      accessorKey: 'fileMimetype',
      enableColumnFilter: false,
    },
    {
      header: 'View Image',
      accessorKey: 'signedUrlOriginal',
      enableColumnFilter: false,
      cell: renderSignedUrl,
    },
  ];

  return (
    <ReactTable
      rootName="consignmentImage"
      parentColumns={parentColumns}
      data={consignmentImages}
      doEdit={false}
      doShow={false}
      handleDelete={consignmentImageDeleteClicked}
      currentUser={currentUser}
      hiddenColumns={['id']}
    />
  );
};

export default ConsignmentImageList;
