const consignmentFormSchema = {
  type: 'object',
  required: ['siteId', 'supplierid'],
  properties: {
    containerIdentifier: { type: 'string', minLength: 6, maxLength: 255 },
    containerReference: { type: 'string', minLength: 6, maxLength: 255 },
    shipmentNumber: { type: 'number', minimum: 0 },
  },
};

export { consignmentFormSchema };
