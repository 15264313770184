import { useState } from 'react';
import { Modal, Button, ButtonGroup } from 'react-bootstrap';
import { Scanner } from '@yudiel/react-qr-scanner';

function ScanReceiptModal(props) {
  const { show, onCancel, onScan } = props;
  const [paused, setPaused] = useState(false);

  return (
    <Modal id="goods-receipt-new-scan-form" show={show} onHide={onCancel}>
      <Modal.Body style={{ minHeight: 'calc(100vw)' }}>
        <Scanner allowMultiple onScan={onScan} paused={paused} />
      </Modal.Body>
      <Modal.Footer>
        <ButtonGroup>
          <Button variant="secondary" onClick={() => setPaused(!paused)}>
            {paused ? 'Unpause Scanner' : 'Pause Scanner'}
          </Button>
          <Button variant="danger" onClick={onCancel}>
            Cancel
          </Button>
        </ButtonGroup>
      </Modal.Footer>
    </Modal>
  );
}

export default ScanReceiptModal;
