// export const consignmentDefaultValues = {};

// import { DateTime } from 'luxon';

export function getConsignmentItemDefaultValues() {
  return {
    quantity: 0,
    weightKg: 0,
    // deliveredAt: DateTime.now()
    //   .plus({ days: 1 })
    //   .set({ hour: 12, minute: 0, second: 0, millisecond: 0 })
    //   .toISO(),
  };
}
