import { useMutation } from '@apollo/client';
import { validate as uuidValidate } from 'uuid';
import { useDispatch } from 'react-redux';

import ReactTable from '../../components/react_table/react_table';

import { productSupplierCatalogItemDelete as productSupplierCatalogItemDeleteMutation } from '../../graphql/product_supplier_catalog_item_queries';
import { toastSuccess, toastWarning, toastError } from '../../lib/toast_helpers';
import { settingsSet } from '../../store/settings_slice';
import * as updateFunctions from '../../update_functions';

const ProductSupplierCatalogItemList = (props) => {
  const {
    currentUser,
    productSupplierCatalogItems,
    settingsTenant,
    settingsOnline,
    onDelete,
    onEdit,
  } = props;

  const dispatch = useDispatch();
  const [productSupplierCatalogItemDelete] = useMutation(
    productSupplierCatalogItemDeleteMutation
  );

  const productSupplierCatalogItemEditClicked = (productSupplierCatalogItemId) => {
    onEdit(productSupplierCatalogItemId);
  };

  const productSupplierCatalogItemDeleteClicked = async (e) => {
    const dataId = e.currentTarget.getAttribute('data-id');
    const productSupplierCatalogItemId = uuidValidate(dataId)
      ? dataId
      : parseInt(e.currentTarget.getAttribute('data-id'), 10);
    const mutationData = {
      variables: { id: productSupplierCatalogItemId },
      context: {
        serializationKey: settingsTenant,
        tracked: true,
        recordType: 'ProductSupplierCatalogItemType',
        recordId: productSupplierCatalogItemId,
        mutationType: 'DELETE',
      },
      update: updateFunctions.productSupplierCatalogItemDelete,
    };
    mutationData.optimisticResponse = updateFunctions.optimisticNew({
      mutationName: 'productSupplierCatalogItemDelete',
      mutationData,
    });
    dispatch(
      settingsSet({
        mutating: true,
      })
    );
    if (settingsOnline) {
      try {
        await productSupplierCatalogItemDelete(mutationData);
        toastSuccess('Supplier Catalog Item Link delete ok');
      } catch (err) {
        console.log(err.toString());
        toastError('Supplier Catalog Item Link delete failed');
      } finally {
        dispatch(
          settingsSet({
            mutating: false,
          })
        );
        onDelete();
      }
    } else {
      productSupplierCatalogItemDelete(mutationData);
      toastWarning(
        `Supplier Catalog Item Link delete ok locally. Go online to make permanent`
      );
      dispatch(
        settingsSet({
          mutating: false,
        })
      );
      onDelete();
    }
  };

  // const getProductName = (row) => {
  //   const {
  //     product: { name: productName },
  //   } = row;
  //   return productName;
  // };

  const getSupplierCatalogItemDescription = (row) => {
    const {
      supplierCatalogItem: { description: supplierCatalogItemDescription },
    } = row;
    return supplierCatalogItemDescription;
  };

  const parentColumns = [
    {
      header: 'Id',
      accessorKey: 'id',
    },
    // {
    //   header: 'Product',
    //   id: 'product',
    //   accessorFn: (row) => getProductName(row),
    //   filterType: 'dropdown',
    // },
    {
      header: 'Supplier Catalog Item',
      id: 'supplierCatalogItem',
      accessorFn: (row) => getSupplierCatalogItemDescription(row),
      enableColumnFilter: true,
      filterFn: 'includesString',
    },
    {
      header: 'Quantity',
      accessorKey: 'quantity',
      enableColumnFilter: false,
    },
  ];

  return (
    <ReactTable
      rootName="productSupplierCatalogItem"
      parentColumns={parentColumns}
      data={productSupplierCatalogItems}
      doShow={false}
      handleEdit={productSupplierCatalogItemEditClicked}
      handleDelete={productSupplierCatalogItemDeleteClicked}
      currentUser={currentUser}
      hiddenColumns={['id']}
    />
  );
};

export default ProductSupplierCatalogItemList;
