import { useMemo, useCallback } from 'react';
import { Modal, Button, ButtonToolbar, ButtonGroup } from 'react-bootstrap';
import { useMutation } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { Form as FinalForm } from 'react-final-form';
import setFieldTouched from 'final-form-set-field-touched';
import { v4 as uuidv4 } from 'uuid';

import get from 'lodash.get';
import cloneDeep from 'lodash.clonedeep';

import Field from '../../components/form/rff_field';
import InputField from '../../components/form/input_field';
import SubmitButtonSpinner from '../../components/submit_button_spinner';
import { toastSuccess, toastWarning, toastError } from '../../lib/toast_helpers';
import { settingsSet } from '../../store/settings_slice';
import {
  consignmentImageCreate as consignmentImageCreateMutation,
  consignmentImageUpdate as consignmentImageUpdateMutation,
} from '../../graphql/consignment_image_queries';
import * as updateFunctions from '../../update_functions';
import { coerceInput, pickValues, handleSubmitError } from '../../lib/utils';
import { consignmentImageDefaultValues } from '../../defaults';
import { consignmentImageFormValidator } from '../../validators';
import { consignmentImageWhiteList } from '../../white_lists';

function ConsignmentImageFormModal(props) {
  const dispatch = useDispatch();
  const [consignmentImageCreate] = useMutation(consignmentImageCreateMutation);
  const [consignmentImageUpdate] = useMutation(consignmentImageUpdateMutation);
  const {
    settingsTenant,
    settingsOnline,
    show,
    consignmentId,
    consignmentName,
    consignmentImage,
    onCancel,
    onComplete,
  } = props;

  const initialValues = useMemo(() => {
    if (consignmentImage) {
      const values = pickValues(consignmentImage, consignmentImageWhiteList);
      return { ...values, consignmentId };
    }
    return { ...consignmentImageDefaultValues, consignmentId };
  }, [consignmentImage]);

  const handleFileChange = useCallback((name, onChange, e) => {
    const file = get(e, 'target.files.0');
    if (file) {
      onChange(file);
    }
  }, []);

  const onFormSubmit = async (data) => {
    const uuid = uuidv4();
    const submitData = cloneDeep(data);
    let mutation;
    let mutationMessageAction;
    const input = coerceInput(submitData);
    const mutationData = {
      variables: { input },
      context: {
        hasUpload: true,
        serializationKey: settingsTenant,
        tracked: true,
        recordType: 'ConsignmentImageType',
        recordId: consignmentImage ? consignmentImage.id : uuid,
      },
    };
    if (consignmentImage?.id) {
      mutation = consignmentImageUpdate;
      mutationMessageAction = 'update';
      mutationData.variables.id = consignmentImage.id;
      mutationData.context.mutationType = 'UPDATE';
      mutationData.update = updateFunctions.consignmentImageUpdate;
      mutationData.optimisticResponse = updateFunctions.optimisticNew({
        mutationName: 'consignmentImageUpdate',
        mutationData,
      });
    } else {
      mutation = consignmentImageCreate;
      mutationData.context.mutationType = 'CREATE';
      mutationMessageAction = 'create';
      mutationData.update = updateFunctions.consignmentImageCreate;
      mutationData.optimisticResponse = updateFunctions.optimisticNew({
        mutationName: 'consignmentImageCreate',
        mutationData,
      });
    }
    dispatch(
      settingsSet({
        mutating: true,
      })
    );
    if (settingsOnline) {
      try {
        await mutation(mutationData);
        toastSuccess(`Consignment Image ${mutationMessageAction} succeeded`);
        dispatch(
          settingsSet({
            mutating: false,
          })
        );
        onComplete();
      } catch (err) {
        const { errorMessage, submitErrors } = handleSubmitError(err);
        dispatch(
          settingsSet({
            mutating: false,
          })
        );
        toastError(errorMessage);
        return submitErrors;
      }
    } else {
      mutation(mutationData);
      toastWarning(
        `Consignment Image ${mutationMessageAction} ok locally. Go online to make permanent`
      );
      dispatch(
        settingsSet({
          mutating: false,
        })
      );
      onComplete();
    }
    return undefined;
  };

  return (
    <Modal
      id={`consignment-${consignmentId}-consignment-image-form`}
      show={show}
      onHide={onCancel}
      centered
      size="xl"
      animation={false}
    >
      <FinalForm
        onSubmit={onFormSubmit}
        initialValues={initialValues}
        validate={consignmentImageFormValidator}
        mutators={{ setFieldTouched }}
      >
        {({ handleSubmit, submitting, pristine }) => (
          <form noValidate onSubmit={handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title>{`Add Image for ${consignmentName}`}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <fieldset className="border rounded-3 p-3">
                <legend className="float-none w-auto px-3 fs-6">Image details</legend>
                <Field
                  type="file"
                  name="file"
                  labelWidth={0}
                  inputWidth={12}
                  size="lg"
                  component={InputField}
                  customOnChange={handleFileChange}
                />
              </fieldset>
            </Modal.Body>
            <Modal.Footer>
              <ButtonToolbar className="d-flex justify-content-end w-100">
                <ButtonGroup className="me-2">
                  <Button variant="danger" onClick={onCancel} disabled={submitting}>
                    Cancel
                  </Button>
                  <Button
                    type="button"
                    variant="primary"
                    disabled={pristine || submitting}
                    onClick={handleSubmit}
                  >
                    {submitting && <SubmitButtonSpinner />}
                    {consignmentImage ? 'Update' : 'Create'}
                  </Button>
                </ButtonGroup>
              </ButtonToolbar>
            </Modal.Footer>
          </form>
        )}
      </FinalForm>
    </Modal>
  );
}

export default ConsignmentImageFormModal;
