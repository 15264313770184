export const consignmentIntegers = [];
export const consignmentFloats = [];
export const consignmentIsoStrings = ['estimatedDeliveryAt'];
export const consignmentWhiteList = [
  'id',
  // name is not included as it is constructed on the Model in server
  'siteId',
  'supplierId',
  'containerReference',
  'containerIdentifier',
  'shipmentNumber',
  'estimatedDeliveryAt',
];
export const consignmentOptimisticList = [
  ...consignmentWhiteList,
  'name', // virtual attribute
  'site',
  'supplier',
  'consignmentItems',
  'consignmentImages',
];
