import { useMemo, useState } from 'react';

import {
  Stack,
  Alert,
  ButtonGroup,
  Button,
  Col,
  Row,
  Card,
  Dropdown,
} from 'react-bootstrap';
import { useQuery, useMutation, NetworkStatus } from '@apollo/client';
import { useSelector, useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import cloneDeep from 'lodash.clonedeep';
import get from 'lodash.get';

import ManualReceiptModal from './goods_receipt_show/manual_receipt_form_modal';
import ScanReceiptModal from './goods_receipt_show/scan_receipt_modal';
import { renderOverlay, renderError, renderOffline } from '../components/render_helpers';
import { coerceInput, handleSubmitError } from '../lib/utils';
import { toastSuccess, toastWarning, toastError } from '../lib/toast_helpers';
import { settingsSet } from '../store/settings_slice';

import receiptFragment from '../fragments/receipt_type_base_fragment';
import {
  goodsReceiptShowPageQuery,
  receiptCreate as receiptCreateMutation,
  receiptUpdate as receiptUpdateMutation,
} from '../graphql/receipt_queries';
import * as updateFunctions from '../update_functions';

const GoodsReceiptShow = () => {
  const dispatch = useDispatch();
  const [showManualReceiptModal, setShowManualReceiptModal] = useState(false);
  const [showScanReceiptModal, setShowScanReceiptModal] = useState(false);
  const [lastScanned, setLastScanned] = useState({});
  const settingsTenant = useSelector((state) => state.settings.tenant);
  const settingsMutating = useSelector((state) => state.settings.mutating);
  const settingsOnline = useSelector((state) => state.settings.online);
  const [receiptCreate] = useMutation(receiptCreateMutation);
  const [receiptUpdate] = useMutation(receiptUpdateMutation);
  const {
    data: pageData,
    loading: pageLoading,
    error: pageError,
    networkStatus: pageNetworkStatus,
    client: pageClient,
  } = useQuery(goodsReceiptShowPageQuery, {
    notifyOnNetworkStatusChange: true,
  });

  const pageLoadedOrRefetching = useMemo(
    () => !pageLoading || (pageLoading && pageNetworkStatus === NetworkStatus.refetch),
    [pageLoading, pageNetworkStatus]
  );

  const enums = useMemo(() => get(pageData, 'enums.enums'), [pageData]);

  const [deliveryStateDamaged, deliveryStateUndamaged] = useMemo(
    () => [get(enums, 'DeliveryStates.DAMAGED'), get(enums, 'DeliveryStates.UNDAMAGED')],
    [enums]
  );

  const onFormSubmit = async (data, parsedScannedData) => {
    const uuid = uuidv4();
    const lastScannedPayload = { ...cloneDeep(parsedScannedData), id: data.id || uuid };
    const submitData = cloneDeep(data);
    let mutation;
    let mutationMessageAction;
    const input = coerceInput(submitData);
    const mutationData = {
      variables: { input },
      context: {
        serializationKey: settingsTenant,
        tracked: true,
        recordType: 'ReceiptType',
        recordId: data.id || uuid,
      },
    };
    if (data.id) {
      const {
        context: { recordType, recordId },
      } = mutationData;
      // TODO not sure how this will work as input
      // to optimistic update when consignments child is populated
      const currentData = pageClient.readFragment({
        id: `${recordType}:${recordId}`,
        fragment: receiptFragment,
        fragmentName: 'ReceiptTypeBase',
      });
      mutation = receiptUpdate;
      mutationMessageAction = 'update';
      mutationData.variables.id = data.id;
      mutationData.context.mutationType = 'UPDATE';
      mutationData.update = updateFunctions.receiptUpdate;
      mutationData.optimisticResponse = updateFunctions.optimisticNew({
        mutationName: 'receiptUpdate',
        mutationData,
        currentData,
      });
    } else {
      mutation = receiptCreate;
      mutationData.context.mutationType = 'CREATE';
      mutationMessageAction = 'create';
      mutationData.update = updateFunctions.receiptCreate;
      mutationData.optimisticResponse = updateFunctions.optimisticNew({
        mutationName: 'receiptCreate',
        mutationData,
      });
    }
    dispatch(
      settingsSet({
        mutating: true,
      })
    );
    if (settingsOnline) {
      try {
        const resp = await mutation(mutationData);
        const newId = get(resp, 'data.receiptCreate.id');
        if (newId) {
          lastScannedPayload.id = newId;
        }
        setLastScanned(lastScannedPayload);
        toastSuccess(`Receipt ${mutationMessageAction} succeeded`);
        dispatch(
          settingsSet({
            mutating: false,
          })
        );
      } catch (err) {
        const { errorMessage, submitErrors } = handleSubmitError(err);
        dispatch(
          settingsSet({
            mutating: false,
          })
        );
        toastError(errorMessage);
        return submitErrors;
      }
    } else {
      mutation(mutationData);
      setLastScanned(lastScannedPayload);
      toastWarning(
        `Receipt ${mutationMessageAction} ok locally. Go online to make permanent`
      );
      dispatch(
        settingsSet({
          mutating: false,
        })
      );
    }
    return undefined;
  };

  const handleReceiptMarkDamagedClick = () => {
    const parsedScannedData = { ...lastScanned, deliveryState: deliveryStateDamaged };
    onFormSubmit(
      {
        id: parsedScannedData.id,
        deliveryState: deliveryStateDamaged,
      },
      parsedScannedData
    );
    return true;
  };

  const handleReceiptMarkUndamagedClick = () => {
    const parsedScannedData = { ...lastScanned, deliveryState: deliveryStateUndamaged };
    onFormSubmit(
      {
        id: parsedScannedData.id,
        deliveryState: deliveryStateUndamaged,
      },
      parsedScannedData
    );
    return true;
  };

  const handleManualReceiptModalClick = () => {
    setLastScanned({});
    setShowManualReceiptModal(true);
  };

  const handleManualReceiptModalCancel = () => {
    setShowManualReceiptModal(false);
  };

  const handleManualReceiptModalComplete = (data) => {
    setShowManualReceiptModal(false);
    const submitData = cloneDeep(data);
    const siteLocation = get(pageData, 'siteLocationList', []).find(
      (sl) => sl.id === parseInt(data.siteLocationId, 10)
    );
    submitData.laydownRef = siteLocation.name;
    const consignmentItem = get(pageData, 'consignmentItemList', []).find(
      (ci) => ci.id === parseInt(data.consignmentItemId, 10)
    );
    submitData.consignmentRef = consignmentItem.consignmentItemReference;
    const {
      containerRef = 'containerRef(TBC)',
      consignmentRef,
      description = 'description(TBC)',
      laydownRef,
      consignmentItemId,
      siteLocationId,
    } = submitData;
    const rawValue = [
      containerRef,
      consignmentRef,
      description,
      laydownRef,
      `consignmentItemId=${consignmentItemId}`,
      `siteLocationId=${siteLocationId}`,
    ].join(',');
    const parsedScannedData = {
      containerRef,
      consignmentRef,
      description,
      laydownRef,
      deliveryState: deliveryStateUndamaged,
    };
    onFormSubmit(
      {
        scannedData: rawValue,
        deliveryState: deliveryStateUndamaged,
      },
      parsedScannedData
    );
    return true;
  };

  const handleScanReceiptModalClick = () => {
    setLastScanned({});
    setShowScanReceiptModal(true);
  };

  const handleScanReceiptModalCancel = () => {
    setShowScanReceiptModal(false);
  };

  const handleScanReceiptModalComplete = (results) => {
    setShowScanReceiptModal(false);
    const rawValue = get(results, [0, 'rawValue']);
    if (!rawValue) {
      toastError('Missing scan result');
      return false;
    }
    const [containerRef, consignmentRef, description, laydownRef] = rawValue.split(',');
    if (!laydownRef) {
      toastError('Missing laydown area reference');
      return false;
    }
    const parsedScannedData = {
      containerRef,
      consignmentRef,
      description,
      laydownRef,
      deliveryState: deliveryStateUndamaged,
    };
    onFormSubmit(
      {
        scannedData: rawValue,
        deliveryState: deliveryStateUndamaged,
      },
      parsedScannedData
    );
    return true;
  };

  const renderContent = () => (
    <>
      <ScanReceiptModal
        show={showScanReceiptModal}
        // consignmentId={consignmentId}
        // consignmentName={consignmentName}
        onCancel={handleScanReceiptModalCancel}
        onScan={handleScanReceiptModalComplete}
        settingsTenant={settingsTenant}
        settingsOnline={settingsOnline}
      />
      <ManualReceiptModal
        show={showManualReceiptModal}
        // consignmentId={consignmentId}
        // consignmentName={consignmentName}
        onCancel={handleManualReceiptModalCancel}
        onComplete={handleManualReceiptModalComplete}
        settingsTenant={settingsTenant}
        settingsOnline={settingsOnline}
        siteLocations={get(pageData, 'siteLocationList', [])}
        consignmentItems={get(pageData, 'consignmentItemList', [])}
      />
      <Row className="mt-4 mb-4">
        <Stack gap={3} className="col-md-5 mx-auto">
          {lastScanned.laydownRef && (
            <Alert variant="warning" className="text-center mb-0 p-4 lead">
              Offload to &nbsp;
              <strong>{lastScanned.laydownRef}</strong>
            </Alert>
          )}
          {lastScanned.deliveryState &&
            lastScanned.deliveryState === deliveryStateUndamaged && (
              <Button
                size="lg"
                variant="outline-secondary"
                className="p-4"
                onClick={handleReceiptMarkDamagedClick}
              >
                Mark as Damaged
              </Button>
            )}
          {lastScanned.deliveryState &&
            lastScanned.deliveryState === deliveryStateDamaged && (
              <Button
                size="lg"
                variant="outline-secondary"
                className="p-4"
                onClick={handleReceiptMarkUndamagedClick}
              >
                Mark as Undamaged
              </Button>
            )}
          {/* <Button
            size="lg"
            variant="primary"
            className="p-4"
            onClick={handleScanReceiptModalClick}
          >
            Scan New
          </Button> */}
          <Dropdown as={ButtonGroup}>
            <Button
              style={{ flexGrow: 9 }}
              size="lg"
              variant="primary"
              className="p-4"
              onClick={handleScanReceiptModalClick}
            >
              Scan New
            </Button>
            <Dropdown.Toggle
              split
              size="lg"
              variant="danger"
              className="p-4"
              id="dropdown-split-basic"
              style={{ flexGrow: 1 }}
            />
            <Dropdown.Menu>
              <Dropdown.Item size="lg" onClick={handleManualReceiptModalClick}>
                Add Manual Receipt
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Stack>
      </Row>
      <Row className="mb-4">
        <Col>
          <Card>
            <Card.Body>
              <p>Last Receipt Data</p>
              <pre>{JSON.stringify(lastScanned, undefined, 2)}</pre>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );

  return (
    <div>
      {renderOverlay(pageLoading, settingsMutating, settingsOnline)}
      {renderOffline(settingsOnline)}
      {renderError(pageError)}
      {!pageError && pageLoadedOrRefetching && renderContent()}
    </div>
  );
};

export default GoodsReceiptShow;
