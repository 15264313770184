import { createSlice } from '@reduxjs/toolkit';
import get from 'lodash.get';

const unhydratedInitialState = {
  trackedMutationCount: 0,
  tenant: '',
  tenantLongName: '',
  tenantShortName: '',
  versionCurrent: true,
  mutating: false,
  online: false,
  deviceOnline: false,
  serverOnline: false,
};

const initialState = {
  ...unhydratedInitialState,
  bootswatchStyle: 'sandstone',
  isOpenSidebar: true,
  listColumnFilters: {
    consignment: [],
    consignmentItem: [],
    consignmentImage: [],
    receipt: [],
    site: [],
    sitelocation: [],
    user: [],
    supplier: [],
    supplierCatalog: [],
    supplierCatalogItem: [],
    productCategory: [],
    purchaser: [],
    manufacturer: [],
  },
  listPagination: {
    consignment: { pageIndex: 0, pageSize: 50 },
    consignmentItem: { pageIndex: 0, pageSize: 50 },
    consignmentImage: { pageIndex: 0, pageSize: 50 },
    product: { pageIndex: 0, pageSize: 50 },
    productProduct: { pageIndex: 0, pageSize: 50 },
    productSupplierCatalogItem: { pageIndex: 0, pageSize: 50 },
    receipt: { pageIndex: 0, pageSize: 50 },
    site: { pageIndex: 0, pageSize: 50 },
    siteLocation: { pageIndex: 0, pageSize: 50 },
    user: { pageIndex: 0, pageSize: 50 },
    supplier: { pageIndex: 0, pageSize: 50 },
    supplierCatalog: { pageIndex: 0, pageSize: 50 },
    supplierCatalogItem: { pageIndex: 0, pageSize: 50 },
    productCategory: { pageIndex: 0, pageSize: 50 },
    purchaser: { pageIndex: 0, pageSize: 50 },
    manufacturer: { pageIndex: 0, pageSize: 50 },
  },
};

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    settingsSet(state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase('persist/REHYDRATE', (_, action) => {
        const persistedState = get(action, ['payload', 'settings'], {});
        const persistedListColumnFilters = get(persistedState, 'listColumnFilters', {});
        const persistedListPagination = get(persistedState, 'listPagination', {});
        return {
          ...initialState,
          ...persistedState,
          // stop rehydrate overwriting new table states
          listColumnFilters: {
            ...initialState.listColumnFilters,
            ...persistedListColumnFilters,
          },
          listPagination: {
            ...initialState.listPagination,
            ...persistedListPagination,
          },
          ...unhydratedInitialState,
        };
      })
      .addDefaultCase((state) => state);
  },
});

export const { settingsSet } = settingsSlice.actions;
export default settingsSlice.reducer;
