/* eslint-disable react/jsx-props-no-spreading */
import { Row, Col, Form, InputGroup } from 'react-bootstrap';
import classNames from 'classnames';

import BlurOnlyFormControl from './blur_only_form_control';
import HelpBlock from './help_block';
import LabelBlock from './label_block';
import InvalidBlock from './invalid_block';
import { isInvalid } from './helpers';

const defaultProps = {
  controlOnly: false,
  innerContentOnly: false,
  size: 'sm',
  labelWidth: 3,
  inputWidth: 9,
  defaultSelectOption: true,
  selectOptions: false,
  helpText: '',
  helpBlock: true,
  invalidBlock: true,
  plainText: false,
  showValid: false,
  showInvalid: true,
  optionKey: 'name',
  defaultSelectOptionName: '',
  labelAlign: 'right',
};

const InputField = (props) => {
  const {
    type: rootType,
    accept,
    capture,
    controlOnly,
    innerContentOnly,
    blurOnly,
    noTab,
    placeholder,
    input,
    input: { type: inputType },
    inputStyle,
    inputProps,
    asElement,
    defaultSelectOption,
    defaultSelectOptionName,
    selectOptions,
    optionKey,
    rows,
    plainText,
    readOnly,
    customOnFocus,
    customOnBlur,
    customOnChange,
    showValid,
    showInvalid,
    size,
    meta = {},
    labelWidth,
    helpText,
    helpBlock,
    invalidBlock,
    prepend,
    append,
    children,
    groupStyle,
    groupClassName,
    inputWidth,
    innerContent,
    labelAlign,
    labelColClassName,
    inputColClassName,
    disabled,
  } = { ...defaultProps, ...props };

  const invalid = isInvalid(meta);

  const renderFormControl = () => {
    const type = rootType || inputType; // redux form || final form

    let { onFocus, onBlur, onChange } = input;

    if (customOnFocus) {
      onFocus = (e) => customOnFocus(input.name, input.onFocus, e);
    }
    if (customOnBlur) {
      onBlur = (e) => customOnBlur(input.name, input.onBlur, e);
    }
    if (customOnChange) {
      onChange = (e) => customOnChange(input.name, input.onChange, e);
    }

    const commonProps = {
      ...(type && { type }),
      ...(type !== 'file' && { value: input.value }),
      size,
      onFocus,
      ...(disabled && { disabled }),
      ...(accept && { accept }),
      ...(capture && { capture: true }),
      ...((controlOnly || innerContentOnly) && { id: input.name }),
      ...(placeholder && { placeholder }),
      ...(asElement && { as: asElement }),
      ...(rows && { rows }),
      ...(noTab && { tabIndex: -1 }),
      ...(inputStyle && { style: inputStyle }),
      ...(inputProps && { ...inputProps }),
      ...(showInvalid && { isInvalid: invalid }),
      ...(showValid && { isValid: !invalid }),
      ...(readOnly && { readOnly }),
    };

    if (plainText) {
      return (
        <Form.Control
          plaintext
          readOnly
          value={input.value}
          className={`form-control-${size}`}
          {...(inputStyle && { style: inputStyle })}
        />
      );
    }
    if (selectOptions) {
      return (
        <Form.Control {...commonProps} onChange={onChange} onBlur={onBlur}>
          {defaultSelectOption ? (
            <option key={0} value="">
              {defaultSelectOptionName}
            </option>
          ) : (
            ''
          )}
          {selectOptions.map((option) => (
            <option key={option.id} value={option.id}>
              {option[optionKey]}
            </option>
          ))}
        </Form.Control>
      );
    }
    if (blurOnly) {
      return <BlurOnlyFormControl {...commonProps} onBlur={onBlur} />;
    }
    return <Form.Control {...commonProps} onBlur={onBlur} onChange={onChange} />;
  };

  const renderInnerContent = () => (
    <>
      {labelWidth === 0 && children && <LabelBlock size={size}>{children}</LabelBlock>}
      {prepend || append ? (
        <InputGroup size={size}>
          {prepend && <InputGroup.Text>{prepend}</InputGroup.Text>}
          {renderFormControl()}
          {append && <InputGroup.Text>{append}</InputGroup.Text>}
          {invalidBlock && <InvalidBlock meta={meta} helpText={helpText} />}
        </InputGroup>
      ) : (
        <>
          {renderFormControl()}
          {invalidBlock && <InvalidBlock meta={meta} helpText={helpText} />}
        </>
      )}
      {helpBlock && <HelpBlock meta={meta} helpText={helpText} />}
    </>
  );

  if (controlOnly) {
    return renderFormControl();
  }

  if (innerContentOnly) {
    return innerContent || renderInnerContent();
  }

  return (
    <Form.Group
      {...{ className: groupClassName ? classNames('mb-3', groupClassName) : 'mb-3' }}
      {...(input && input.name && { controlId: input.name })}
      {...(groupStyle && { style: groupStyle })}
      {...(labelWidth > 0 && { as: Row })}
      {...(labelWidth === 0 && inputWidth > 0 && { as: Col, sm: inputWidth })}
      {...(labelWidth === 0 && inputWidth === 0 && { as: Col, sm: 'auto' })}
    >
      {labelWidth > 0 ? (
        <>
          <Form.Label
            column={size || true}
            sm={labelWidth}
            style={{ textAlign: labelAlign }}
            {...(labelColClassName && { className: labelColClassName })}
          >
            {children}
          </Form.Label>
          <Col
            sm={inputWidth}
            {...(inputColClassName && { className: inputColClassName })}
          >
            {innerContent || renderInnerContent()}
          </Col>
        </>
      ) : (
        innerContent || renderInnerContent()
      )}
    </Form.Group>
  );
};

export default InputField;
