export const userIntegers = [];
export const userFloats = [];
export const userIsoStrings = [];
export const userWhiteList = [
  'id',
  'fullName',
  'mobilePhone',
  'email',
  'administrator',
  'receiver',
];
