import {
  consignmentImageListQuery,
  consignmentImageQuery,
} from '../graphql/consignment_image_queries';
import cleanCache from './clean_cache';

export const consignmentImageList = (client, listData) => {
  listData.map((consignmentImage) => {
    const data = { consignmentImage };
    return client.writeQuery({
      query: consignmentImageQuery,
      variables: { id: consignmentImage.id.toString() },
      data,
    });
  });
  cleanCache(client.cache);
};

export const consignmentImageCreate = (
  cache,
  { data: { consignmentImageCreate: createData } }
) => {
  let cacheData;
  try {
    cacheData = cache.readQuery({ query: consignmentImageListQuery });
  } catch {
    cacheData = { consignmentImageList: [] };
  }
  cache.writeQuery({
    query: consignmentImageListQuery,
    data: { consignmentImageList: [...cacheData.consignmentImageList, createData] },
  });
  const data = { consignmentImage: createData };
  cache.writeQuery({
    query: consignmentImageQuery,
    variables: { id: createData.id.toString() },
    data,
  });
  cleanCache(cache);
};

export const consignmentImageUpdate = (
  cache,
  { data: { consignmentImageUpdate: updateData } }
) => {
  const data = { consignmentImage: updateData };
  cache.writeQuery({
    query: consignmentImageQuery,
    variables: { id: updateData.id.toString() },
    data,
  });
  cleanCache(cache);
};

export const consignmentImageDelete = (
  cache,
  { data: { consignmentImageDelete: deleteData } }
) => {
  let cacheData;
  try {
    cacheData = cache.readQuery({ query: consignmentImageListQuery });
  } catch {
    cacheData = { consignmentImageList: [] };
  }
  const data = {
    consignmentImageList: cacheData.consignmentImageList.filter(
      (consignmentImage) => deleteData.id !== consignmentImage.id
    ),
  };
  cache.writeQuery({ query: consignmentImageListQuery, data });
  cache.evict({ fieldName: 'consignmentImage', args: { id: deleteData.id.toString() } });
  cleanCache(cache);
};
