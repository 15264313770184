import { useMutation } from '@apollo/client';
import { validate as uuidValidate } from 'uuid';
import { useDispatch } from 'react-redux';

import { renderDateString } from '../../components/render_helpers';
import ReactTable from '../../components/react_table/react_table';

import { consignmentItemDelete as consignmentItemDeleteMutation } from '../../graphql/consignment_item_queries';
import { toastSuccess, toastWarning, toastError } from '../../lib/toast_helpers';
import { settingsSet } from '../../store/settings_slice';
import * as updateFunctions from '../../update_functions';

const ConsignmentItemList = (props) => {
  const { currentUser, consignmentItems, settingsTenant, settingsOnline, onDelete } =
    props;

  const dispatch = useDispatch();
  const [consignmentItemDelete] = useMutation(consignmentItemDeleteMutation);

  const consignmentItemDeleteClicked = async (e) => {
    const dataId = e.currentTarget.getAttribute('data-id');
    const consignmentItemId = uuidValidate(dataId)
      ? dataId
      : parseInt(e.currentTarget.getAttribute('data-id'), 10);
    const mutationData = {
      variables: { id: consignmentItemId },
      context: {
        serializationKey: settingsTenant,
        tracked: true,
        recordType: 'ConsignmentItemType',
        recordId: consignmentItemId,
        mutationType: 'DELETE',
      },
      update: updateFunctions.consignmentItemDelete,
    };
    mutationData.optimisticResponse = updateFunctions.optimisticNew({
      mutationName: 'consignmentItemDelete',
      mutationData,
    });
    dispatch(
      settingsSet({
        mutating: true,
      })
    );
    if (settingsOnline) {
      try {
        await consignmentItemDelete(mutationData);
        toastSuccess('Consignment Item delete ok');
      } catch (err) {
        console.log(err.toString());
        toastError('Consignment Item delete failed');
      } finally {
        dispatch(
          settingsSet({
            mutating: false,
          })
        );
        onDelete();
      }
    } else {
      consignmentItemDelete(mutationData);
      toastWarning(`Consignment Item delete ok locally. Go online to make permanent`);
      dispatch(
        settingsSet({
          mutating: false,
        })
      );
      onDelete();
    }
  };

  const parentColumns = [
    {
      header: 'Id',
      accessorKey: 'id',
    },
    {
      header: 'Item Ref',
      accessorKey: 'consignmentItemReference',
      enableColumnFilter: true,
      filterFn: 'includesString',
    },
    {
      header: 'Description',
      accessorKey: 'description',
      enableColumnFilter: true,
      filterFn: 'includesString',
    },
    {
      header: 'Qty',
      accessorKey: 'quantity',
      enableColumnFilter: false,
    },
    {
      header: 'Kg',
      accessorKey: 'weightKg',
      enableColumnFilter: false,
    },

    {
      header: 'Delivered At',
      accessorKey: 'deliveredAt',
      enableColumnFilter: false,
      accessorFn: ({ deliveredAt }) => renderDateString(deliveredAt),
    },
  ];

  return (
    <ReactTable
      rootName="consignmentItem"
      parentColumns={parentColumns}
      data={consignmentItems}
      doEdit={false}
      doShow={false}
      handleDelete={consignmentItemDeleteClicked}
      currentUser={currentUser}
      hiddenColumns={['id']}
    />
  );
};

export default ConsignmentItemList;
