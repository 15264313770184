import { Navigate, useRoutes } from 'react-router-dom';
import compact from 'lodash.compact';

import Page404 from './containers/page_404';

import Logout from './containers/logout';
import Login from './containers/login';

import BomImportForm from './containers/bom_import_form';
import SrackImportForm from './containers/srack_import_form';

import ConsignmentList from './containers/consignment_list';
import ConsignmentShow from './containers/consignment_show';
import ConsignmentForm from './containers/consignment_form';
import ConsignmentItemList from './containers/consignment_item_list';
import ConsignmentItemForm from './containers/consignment_item_form';
import ConsignmentImageList from './containers/consignment_image_list';
import ConsignmentImageForm from './containers/consignment_image_form';
import ReceiptList from './containers/receipt_list';
import ReceiptForm from './containers/receipt_form';
import GoodsReceiptShow from './containers/goods_receipt_show';
import ProductList from './containers/product_list';
import ProductForm from './containers/product_form';
import SiteList from './containers/site_list';
import SiteForm from './containers/site_form';
import SiteLocationList from './containers/site_location_list';
import SiteLocationForm from './containers/site_location_form';
import ProductCategoryList from './containers/product_category_list';
import ProductCategoryForm from './containers/product_category_form';
import PurchaserList from './containers/purchaser_list';
import PurchaserForm from './containers/purchaser_form';
import ManufacturerList from './containers/manufacturer_list';
import ManufacturerForm from './containers/manufacturer_form';
import SupplierList from './containers/supplier_list';
import SupplierForm from './containers/supplier_form';
import SupplierCatalogList from './containers/supplier_catalog_list';
import SupplierCatalogForm from './containers/supplier_catalog_form';
import SupplierCatalogItemList from './containers/supplier_catalog_item_list';
import SupplierCatalogItemForm from './containers/supplier_catalog_item_form';
import UserList from './containers/user_list';
import UserForm from './containers/user_form';

import AuthWrapper from './layouts/auth_wrapper';
import AuthLayout from './layouts/auth_layout';
import FluidAndScrollLayout from './layouts/fluid_and_scroll_layout';

const auth = (Component) => (
  <AuthWrapper>
    <Component />
  </AuthWrapper>
);

const restfulAuthRoutes = ({
  path,
  listElement,
  showElement,
  newElement,
  editElement,
  singleton = false,
}) => ({
  path,
  children: compact([
    newElement && {
      path: 'new',
      element: auth(newElement),
    },
    showElement && {
      path: singleton ? '' : ':id',
      element: auth(showElement),
    },
    editElement && {
      path: singleton ? 'edit' : ':id/edit',
      element: auth(editElement),
    },
    !singleton &&
      listElement && {
        path: '',
        element: auth(listElement),
      },
  ]),
});

const AppRoutes = () =>
  useRoutes([
    {
      path: '/',
      element: <Navigate to="/goods_receipt" />,
    },
    {
      path: '/logout',
      element: <Navigate to="/auth/logout" />,
    },
    {
      path: '/login',
      element: <Navigate to="/auth/login" />,
    },
    {
      path: '/404',
      element: <Page404 />,
    },
    {
      path: '/auth',
      element: <AuthLayout />,
      children: [
        {
          path: 'login',
          element: <Login />,
        },
        {
          path: 'logout',
          element: <Logout />,
        },
      ],
    },
    {
      element: <FluidAndScrollLayout />,
      children: [
        restfulAuthRoutes({
          path: '/bom_imports',
          newElement: BomImportForm,
        }),
        restfulAuthRoutes({
          path: '/srack_imports',
          newElement: SrackImportForm,
        }),
        restfulAuthRoutes({
          path: '/consignments',
          listElement: ConsignmentList,
          showElement: ConsignmentShow,
          newElement: ConsignmentForm,
          editElement: ConsignmentForm,
        }),
        restfulAuthRoutes({
          path: '/consignment_items',
          listElement: ConsignmentItemList,
          newElement: ConsignmentItemForm,
          editElement: ConsignmentItemForm,
        }),
        restfulAuthRoutes({
          path: '/consignment_images',
          listElement: ConsignmentImageList,
          newElement: ConsignmentImageForm,
          editElement: ConsignmentImageForm,
        }),
        restfulAuthRoutes({
          path: '/goods_receipt',
          singleton: true,
          showElement: GoodsReceiptShow,
        }),
        restfulAuthRoutes({
          path: '/products',
          listElement: ProductList,
          newElement: ProductForm,
          editElement: ProductForm,
        }),
        restfulAuthRoutes({
          path: '/receipts',
          listElement: ReceiptList,
          newElement: ReceiptForm,
          editElement: ReceiptForm,
        }),
        restfulAuthRoutes({
          path: '/sites',
          listElement: SiteList,
          newElement: SiteForm,
          editElement: SiteForm,
        }),
        restfulAuthRoutes({
          path: '/site_locations',
          listElement: SiteLocationList,
          newElement: SiteLocationForm,
          editElement: SiteLocationForm,
        }),
        restfulAuthRoutes({
          path: '/product_categories',
          listElement: ProductCategoryList,
          newElement: ProductCategoryForm,
          editElement: ProductCategoryForm,
        }),
        restfulAuthRoutes({
          path: '/purchasers',
          listElement: PurchaserList,
          newElement: PurchaserForm,
          editElement: PurchaserForm,
        }),
        restfulAuthRoutes({
          path: '/manufacturers',
          listElement: ManufacturerList,
          newElement: ManufacturerForm,
          editElement: ManufacturerForm,
        }),
        restfulAuthRoutes({
          path: '/suppliers',
          listElement: SupplierList,
          newElement: SupplierForm,
          editElement: SupplierForm,
        }),
        restfulAuthRoutes({
          path: '/supplier_catalogs',
          listElement: SupplierCatalogList,
          newElement: SupplierCatalogForm,
          editElement: SupplierCatalogForm,
        }),
        restfulAuthRoutes({
          path: '/supplier_catalog_items',
          listElement: SupplierCatalogItemList,
          newElement: SupplierCatalogItemForm,
          editElement: SupplierCatalogItemForm,
        }),
        restfulAuthRoutes({
          path: '/users',
          listElement: UserList,
          newElement: UserForm,
          editElement: UserForm,
        }),
      ],
    },
    {
      path: '/*',
      element: <Navigate to="/404" />,
    },
  ]);

export default AppRoutes;
